/*
 *  Copyright (C) Healabs 2022 - All Rights Reserved
 *  Unauthorized copying of this file, via any medium is strictly prohibited
 *  Proprietary and confidential
 *
 */

import crud from '@/services/crud'

let {get, list, create, update, remove} = crud('/specialities')

export {get, list, create, update, remove}
