<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x">
    <v-card-title class=" font-alt pb-3">Spécialités</v-card-title>
    <v-card-subtitle class="o-60">Liste des spécialités activés et désactivés</v-card-subtitle>
    <!--    <div class="search-list">-->
    <!--      <v-btn fab x-small color="grey lighten-3" class="elevation-x">-->
    <!--        <font-awesome-icon :icon="['fad', 'search']" size="lg" />-->
    <!--      </v-btn>-->
    <!--    </div>-->
    <skeleton-list v-if="loading" />
    <v-list v-else class="pa-0">
      <v-list-item two-line class="click-to-create" :class="$vuetify.theme.dark ? '' : 'grey lighten-4'" @click.stop="specialtyForm = true">
        <v-list-item-avatar>
          <v-avatar size="40" color="success lighten-1">
            <font-awesome-icon :icon="['fas', 'plus']" size="1x" class="white--text" />
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-alt font-weight-medium">Ajouter une spécialité</v-list-item-title>
          <v-list-item-subtitle class="op-60 font-weight-light">Créer une nouvelle spécialité</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <empty-list v-if="specialities.length === 0" title="Aucune spécialité" subtitle="L'établissement ne possède aucune spécialité." />
      <template v-for="(specialty, index) in specialities">
        <v-list-item :key="specialty.id" two-line @click.stop="select(specialty)">
          <v-list-item-content>
            <v-list-item-title class="font-alt">{{ specialty.label }}</v-list-item-title>
            <v-list-item-subtitle class="op-60 font-weight-light">Type de spécialité</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="index < specialities.length -1" :key="index" />
      </template>
      <div v-if="totalItems > itemsPerPage" class="py-3 d-flex justify-center">
        <v-pagination
          v-model="page"
          :length="pageCount"
          total-visible="5"
          color="grey"
          @input="loadSpecialties"
        />
      </div>
    </v-list>
    <speciality-form v-if="specialtyForm" :specialty="item" @close="closeDialog" @update-specialities="updateListItem" @add-specialities="addListItem" />
  </v-card>
</template>

<script>
  import EmptyList from '@/modules/core/components/EmptyList'
  import SkeletonList from '@/modules/core/components/SkeletonList'
  import SpecialityForm from '@/modules/specialities/components/SpecialityForm'
  import * as specialities from '@/modules/specialities/api'
  import {removeItem} from '@/utils/array'
  export default {
    name: 'SpecialitiesList',
    components: {SpecialityForm, SkeletonList, EmptyList},
    data() {
      return {
        loading: false,
        specialities: [],
        specialtyForm: false,
        item: null,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        totalItems: 0
      }
    },
    created() {
      this.loadSpecialties()
    },
    methods: {
      closeDialog() {
        this.specialtyForm = false
        this.item = null
        this.remove()
      },
      loadSpecialties() {
        this.loading = true
        try {
          specialities.list({
            params: {
              page: this.page,
              itemsPerPage: this.itemsPerPage
            }
          }).then((response) => {
            this.specialities = response.data['hydra:member']
            this.totalItems = response.data['hydra:totalItems']
            this.pageCount = Math.ceil(this.totalItems / this.itemsPerPage)
          })
        } catch (error) {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/components/Snackbar'),
            type: 'error',
            title: 'Une erreur est survenue'
          })
        } finally {
          this.loading = false
        }
      },
      updateListItem(data){
        if (data) {
          Object.assign(this.specialities[this.specialities.indexOf(this.item)], data)
        }
        return false
      },
      addListItem(data){
        if (data) {
          this.specialities.push(data)
        }
        return false
      },
      select(specialty) {
        this.specialtyForm = true
        this.item = specialty
      },
      remove(specialty) {
        removeItem(this.specialities, specialty)
      }
    }
  }
</script>
